import { FormEvent, useRef, useState } from "react";
import { useUser } from "../../contextAPI/user";
import { useNavigate } from "react-router";
import http from "./../../services/http";
const Login = () => {
  const { setToken, setIsLoggedIn } = useUser();
  const [loading, setLoading] = useState(false);
  const refEmail = useRef<HTMLInputElement>(null);
  const refPassword = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!refEmail.current || !refEmail.current.value || !refPassword.current || !refPassword.current.value) {
      return;
    }
    setLoading(true);
    const data = new FormData();
    data.append("username", refEmail.current.value);
    data.append("password", refPassword.current.value);
    http("/login", { method: "POST", data })
      .then((res) => {
        if (res.status === 200) {
          setToken(res.data.access_token);
          setIsLoggedIn(true);
          localStorage.setItem("__t", res.data.access_token);
          navigate("/");
        } else {
          throw res;
        }
      })
      .catch((err) => {
        alert("username or password is incorrect");
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="flex justify-center items-center min-h-screen bg-violet-900">
      <div className="bg-white/80 shadow-md rounded px-8 pt-6 pb-8 mb-4 w-full max-w-md">
        <h2 className="text-3xl font-bold mb-6 text-center text-white">
          <span className="bg-gradient-to-r text-transparent from-blue-500 to-purple-500 bg-clip-text">LogIn</span>
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-6">
            <label htmlFor="username" className="block text-gray-700 text-sm font-bold mb-2">
              <i className="fas fa-envelope mr-2"></i>Username
            </label>
            <div>
              <input
                ref={refEmail}
                id="username"
                type="username"
                className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Enter your username"
              />
            </div>
          </div>
          <div className="mb-6">
            <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2">
              <i className="fas fa-lock mr-2"></i>Password
            </label>
            <div>
              <input
                ref={refPassword}
                id="password"
                type="password"
                className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Enter your password"
              />
            </div>
          </div>
          <div className="flex items-center justify-center">
            <button
              type="submit"
              disabled={loading}
              className="bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-700 hover:to-purple-700 text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline w-full"
            >
              LogIn
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
