import { Fragment, useEffect, useState } from "react";
import TargetsList from "./components/TargetsList";
import { ILink, ILog, IPage, ISession, ISite, ITarget } from "../../utils/types";
import TargetLinksList from "./components/LinksList";
import SessionList from "./components/SessionList";
import LogsList from "./components/LogsList";
import http from "../../services/http";
import { useUser } from "../../contextAPI/user";

interface ITargetSelectedLink {
  section: "LINKS";
  target: ITarget;
  link?: ILink | null;
  sessions?: ISession | null;
  logs?: ILog | null;
}
interface ITargetSelectedEdit {
  section: "EDIT";
  target: ITarget;
}

export type ITargetSelected = ITargetSelectedLink | ITargetSelectedEdit;

const Targets = () => {
  const { token } = useUser();
  const [targetSelected, setTargetSelected] = useState<ITargetSelected | null>(null);
  const [pages, setPages] = useState<IPage[]>([]);
  const [sites, setSites] = useState<ISite[]>([]);

  useEffect(() => {
    http("/sites/list", { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        if (res.status === 200) {
          setSites(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  return (
    <Fragment>
      <div className="my-6 grid lg:grid-cols-3 grid-cols-1 gap-8">
        <TargetsList targetSelected={targetSelected} setTargetSelected={setTargetSelected} />
        {targetSelected && targetSelected.section === "LINKS" && targetSelected.target && (
          <TargetLinksList targetSelected={targetSelected} setTargetSelected={setTargetSelected} sites={sites} setPages={setPages} pages={pages} />
        )}
        {targetSelected && targetSelected.section === "LINKS" && targetSelected.link && <SessionList targetSelected={targetSelected} setTargetSelected={setTargetSelected} />}
        {targetSelected && targetSelected.section === "LINKS" && targetSelected.sessions && <LogsList targetSelected={targetSelected} />}
      </div>
    </Fragment>
  );
};

export default Targets;
