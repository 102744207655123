import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { textBreak } from "../../../utils/functions";
import { ITargetSelected } from "..";
import { ISession } from "../../../utils/types";
import classNames from "classnames";
import { useUser } from "../../../contextAPI/user";
import http from "../../../services/http";
interface IProps {
  targetSelected: ITargetSelected | null;
  setTargetSelected: Dispatch<SetStateAction<ITargetSelected | null>>;
}
const SessionList = ({ setTargetSelected, targetSelected }: IProps) => {
  const { token } = useUser();
  const [sessionData, setSessionData] = useState<ISession[]>([]);

  const getSessionsData = useCallback(() => {
    let d = new FormData();
    targetSelected && targetSelected.section === "LINKS" && targetSelected.link && d.append("link_id", targetSelected.link.id.toString());
    http("/target/link/sessions", { method: "POST", headers: { Authorization: `Bearer ${token}` }, data: d })
      .then((res) => {
        if (res.status === 200) {
          setSessionData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [targetSelected, token]);

  useEffect(() => {
    getSessionsData();
  }, [getSessionsData]);

  return (
    <div className="w-full text-sm font-medium border border-gray-200 rounded-lg align-top h-fit">
      {/* <div className="w-full text-center px-4 py-2 text-white bg-blue-700 border-b border-gray-200 rounded-t-lg cursor-pointer">
        <span>Create link</span>
      </div> */}
      <div className="text-xs text-zinc-400 my-2 px-2">List of Sessions</div>

      {sessionData.map((s) => (
        <div
          key={s.id}
          className="w-full flex justify-between items-center px-2 py-2 border-b border-gray-200 cursor-pointer hover:bg-gray-100 hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:text-blue-700 gap-1"
        >
          <span
            className={classNames({
              "text-blue-800 font-bold": targetSelected && targetSelected.section === "LINKS" && targetSelected.sessions && targetSelected.sessions.id === s.id,
            })}
            title={s.session}
            onClick={() =>
              targetSelected &&
              targetSelected.section === "LINKS" &&
              targetSelected.target &&
              targetSelected.link &&
              setTargetSelected({ section: "LINKS", target: targetSelected.target, link: targetSelected.link, sessions: s })
            }
          >
            {textBreak(s.session, 10)}
          </span>
        </div>
      ))}
    </div>
  );
};

export default SessionList;
