import { useState } from "react";
import Modal from "react-modal";
import { Routes } from "./pages/Routes";
import UserProvider from "./contextAPI/user";
import "./assets/main.css";

Modal.setAppElement("#modal");

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(Boolean(localStorage.getItem("__t")));
  const [token, setToken] = useState<string | null>(localStorage.getItem("__t"));
  return (
    <UserProvider token={token} setToken={setToken} isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn}>
      <Routes isAuthorized={isLoggedIn} />
    </UserProvider>
  );
};

export default App;
