import axios from "axios";
import config from "./config.json";

const http = axios.create({ baseURL: config.baseAddress });

const token = localStorage.getItem("__t");

http.interceptors.request.use(
  (onFulfilled) => {
    if (token) {
      onFulfilled.headers.Authorization = `Bearer ${token}`;
    }
    return onFulfilled;
  },
  (onRejected) => {
    console.log("request => ", onRejected);
    return Promise.reject(onRejected);
  }
);
http.interceptors.response.use(
  (onFulfilled) => onFulfilled,
  (onRejected) => {
    if (onRejected.response.status === 401) {
      localStorage.removeItem("__t");
      window.location.reload();
    }
    return Promise.reject(onRejected);
  }
);

export default http;
