import { ReactNode, useEffect, useState } from "react";
import ReactCopyToClipboard from "react-copy-to-clipboard";

const CopyToClipboard = ({ text, children }: { text: string; children: ReactNode | JSX.Element }) => {
  const [isCopy, setIsCopy] = useState(false);
  useEffect(() => {
    if (isCopy) {
      setTimeout(() => {
        setIsCopy(false);
      }, 3000);
    }
  }, [isCopy]);

  return (
    <ReactCopyToClipboard text={text} onCopy={() => setIsCopy(true)}>
      <div className="relative inline-block">
        {children}
        {isCopy && <div className="absolute left-full translate-x-1 top-0 bg-white rounded-2xl py-1 px-2">copyed</div>}
      </div>
    </ReactCopyToClipboard>
  );
};

export default CopyToClipboard;
