import { FC, useCallback, useEffect, useState } from "react";
import { ITargetSelected } from "..";
import { ILog } from "../../../utils/types";
import { useUser } from "../../../contextAPI/user";
import http from "../../../services/http";
interface IProps {
  targetSelected: ITargetSelected | null;
}
const LogsList:FC<IProps> = ({ targetSelected }) => {
  const { token } = useUser();
  const [logsData, setLogsData] = useState<ILog[]>([]);

  const getLogsData = useCallback(() => {
    let d = new FormData();
    targetSelected && targetSelected.section === "LINKS" && targetSelected.sessions && d.append("session_id", targetSelected.sessions.id.toString());
    http("/sessions/logs", { method: "POST", headers: { Authorization: `Bearer ${token}` }, data: d })
      .then((res) => {
        if (res.status === 200) {
          setLogsData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [targetSelected, token]);

  useEffect(() => {
    getLogsData();
  }, [getLogsData]);

  return (
    <div className="col-span-3 w-full border border-gray-200 rounded-lg align-top p-2 my-2 h-fit">
      <div className="relative overflow-x-auto">
        <table className="table w-full text-sm text-left rtl:text-right text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3">
                #
              </th>
              <th scope="col" className="px-6 py-3">
                page
              </th>
              <th scope="col" className="px-6 py-3">
                content
              </th>
              <th scope="col" className="px-6 py-3">
                created_at
              </th>
            </tr>
          </thead>
          <tbody>
            {logsData &&
              logsData.map((l, idx) => (
                <tr key={idx} className="bg-white border-b">
                  <th className="px-6 py-4" scope="row">
                    {l.id}
                  </th>
                  <td className="px-6 py-4">{l.page}</td>
                  <td className="px-6 py-4">{l.content}</td>
                  <td className="px-6 py-4">{l.created_at}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LogsList;
