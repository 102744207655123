import { Dispatch, FC, FormEvent, Fragment, SetStateAction, useCallback, useEffect, useState } from "react";
import { ITargetSelected } from "..";
import http from "../../../services/http";
import BaseConfig from "./../../../services/config.json";
import { useUser } from "../../../contextAPI/user";
import { IInput, ILink, IPage, ISite } from "../../../utils/types";
import classNames from "classnames";
import Modal from "react-modal";
import CopyToClipboard from "../../dashboard/components/CopyToClipboard";
interface IProps {
  targetSelected: ITargetSelected | null;
  setTargetSelected: Dispatch<SetStateAction<ITargetSelected | null>>;
  sites: ISite[];
  setPages: Dispatch<SetStateAction<IPage[]>>;
  pages: IPage[];
}
const LinksList: FC<IProps> = ({ setTargetSelected, targetSelected, sites, pages, setPages }) => {
  const [linkSelected, setLinkSelected] = useState<ILink>();
  const [isOpenEditLinkModal, setIsOpenEditLinkModal] = useState(false);
  const [inputEditValue, setInputEditValue] = useState("");
  const { token } = useUser();
  const [linksData, setLinksData] = useState<ILink[]>([]);
  const [isOpenCreateLinkModal, setIsOpenCreateLinkModal] = useState(false);
  const [inputs, setInputs] = useState<IInput[]>([]);

  const getListLinks = useCallback(() => {
    let d = new FormData();
    targetSelected && targetSelected.section === "LINKS" && d.append("target_id", targetSelected.target.id.toString());
    http("/target/link/list", {
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: d,
    })
      .then((res) => {
        if (res.status === 200) {
          setLinksData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [targetSelected, token]);

  useEffect(() => {
    getListLinks();
  }, [getListLinks]);

  useEffect(() => {
    if (linkSelected) {
      setInputEditValue(linkSelected.address);
    }
  }, [linkSelected]);

  const handleDeleteLink = (id: number) => {
    const confirm = window.confirm("Are you sure to delete the link?");
    if (confirm) {
      const d = new FormData();
      d.append("id", id.toString());
      http("/target/link/delete", {
        method: "delete",
        headers: { Authorization: `Bearer ${token}` },
        data: d,
      })
        .then((res) => {
          if (res.status === 200) {
            getListLinks();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleSelectLinkForEdit = (l: ILink) => {
    setLinkSelected(l);
    setIsOpenEditLinkModal(true);
  };

  const handleCreateLink = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = new FormData(e.currentTarget);
    const d = new FormData();
    let keyParser: { [key in string]: string } = {
      address: "address",
      site: "s_id",
      page: "p_id",
    };
    let inputs_data = [];
    for (const [name, value] of form) {
      if (name.split("-")[0] === "input") {
        inputs_data.push({ input_id: name.split("-")[1], content: value });
      }
      keyParser[name] && d.append(keyParser[name], value);
    }
    targetSelected && targetSelected.target && d.append("t_id", targetSelected.target.id.toString());
    d.append("inputs", JSON.stringify(inputs_data));
    http("/link/create", {
      headers: { Authorization: `Bearer ${token}` },
      method: "POST",
      data: d,
    })
      .then((res) => {
        if (res.status === 200) {
          getListLinks();
          setIsOpenCreateLinkModal(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangeStatus = (l: ILink) => {
    const d = new FormData();
    d.append("id", l.id.toString());
    d.append("status", `${+!Boolean(l.status)}`);
    http("/target/link/status", {
      headers: { Authorization: `Bearer ${token}` },
      method: "POST",
      data: d,
    })
      .then((res) => {
        if (res.status === 200) {
          getListLinks();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmitEdit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const d = new FormData();
    linkSelected && d.append("id", linkSelected.id.toString());
    d.append("address", inputEditValue);
    linkSelected && d.append("status", `${+linkSelected.status}`);
    http("/target/link/edit", {
      headers: { Authorization: `Bearer ${token}` },
      method: "POST",
      data: d,
    })
      .then((res) => {
        if (res.status === 200) {
          getListLinks();
          setIsOpenEditLinkModal(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleGetPages = (val: string) => {
    const d = new FormData();
    d.append("s_id", val);
    http("/pages/list", {
      headers: { Authorization: `Bearer ${token}` },
      method: "POST",
      data: d,
    })
      .then((res) => {
        if (res.status === 200) {
          setPages(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleGetInputs = (val: string) => {
    const d = new FormData();
    d.append("p_id", val);
    http("/inputs/list", {
      headers: { Authorization: `Bearer ${token}` },
      method: "POST",
      data: d,
    })
      .then((res) => {
        if (res.status === 200) {
          setInputs(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Fragment>
      <div className="inline-block text-sm font-medium border border-gray-200 rounded-lg align-top h-fit">
        <div
          onClick={() => setIsOpenCreateLinkModal(true)}
          className="w-full text-center px-4 py-2 text-white bg-blue-700 hover:bg-blue-800 border-b border-gray-200 rounded-t-lg cursor-pointer"
        >
          <span>Create link</span>
        </div>
        <div className="text-xs text-zinc-400 my-2 px-2">List of links</div>

        {linksData.map((l) => (
          <div
            key={l.id}
            className="w-full flex justify-between items-center px-2 py-2 border-b border-gray-200 cursor-pointer hover:bg-gray-100 hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:text-blue-700 gap-1"
          >
            <CopyToClipboard text={`https://${l.site}.${BaseConfig._domain}?${l.address}`}>
              <span
                className={classNames({
                  "text-blue-800 font-bold": targetSelected && targetSelected.section === "LINKS" && targetSelected.link && targetSelected.link.id === l.id,
                })}
                title={l.address}
                onClick={() => {
                  targetSelected &&
                    targetSelected.section === "LINKS" &&
                    targetSelected.target &&
                    setTargetSelected({
                      section: "LINKS",
                      target: targetSelected.target,
                      link: l,
                    });
                }}
              >
                {`https://${l.site}.${BaseConfig._domain}?${l.address}`}
              </span>
            </CopyToClipboard>
            <div className="flex">
              <button
                className="border-2 border-red-500 mx-0.5 p-1 rounded-md flex justify-center items-center hover:bg-red-200 group transition-colors"
                onClick={() => handleDeleteLink(l.id)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" className="text-red-500 ">
                  <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0" />
                </svg>
              </button>
              <button
                onClick={() => handleSelectLinkForEdit(l)}
                className="border-2 border-blue-400 mx-0.5 p-1 rounded-lg flex justify-center items-center hover:bg-blue-200 group transition-colors"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" className="text-blue-400">
                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                  <path
                    fillRule="evenodd"
                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                  />
                </svg>
              </button>
              <button
                onClick={() => handleChangeStatus(l)}
                className={classNames("border-2 border-white mx-0.5 p-1 rounded-lg flex justify-center items-center group transition-colors", {
                  "bg-red-500": !Boolean(l.status),
                  "bg-green-500": Boolean(l.status),
                })}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" className="text-white">
                  <path d="M7.5 1v7h1V1z" />
                  <path d="M3 8.812a5 5 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812" />
                </svg>
              </button>
            </div>
          </div>
        ))}
      </div>

      <Modal
        isOpen={isOpenCreateLinkModal}
        style={{
          overlay: { zIndex: 999 },
          content: {
            width: "40rem",
            margin: "auto",
            minHeight: "16rem",
            position: "relative",
          },
        }}
      >
        <button onClick={() => setIsOpenCreateLinkModal(false)} className="absolute right-4">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
          </svg>
        </button>
        <div className="text-center font-bold">New Target</div>
        <form onSubmit={handleCreateLink}>
          <div>
            <input
              type="text"
              id="address"
              name="address"
              className="bg-gray-50 mt-6 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
              placeholder="Address"
            />
            <div className="flex w-full gap-4">
              <div className="w-1/2">
                <select
                  name="site"
                  onChange={(e) => handleGetPages(e.currentTarget.value)}
                  className="w-full bg-gray-50 mt-6 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
                >
                  <option selected disabled>
                    Sites
                  </option>
                  {sites.map((s) => (
                    <option key={s.id} value={s.id}>
                      {s.name} - {s.prefix}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-1/2">
                {pages.length > 0 && (
                  <select
                    name="page"
                    onChange={(e) => handleGetInputs(e.currentTarget.value)}
                    className="w-full bg-gray-50 mt-6 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
                  >
                    <option selected disabled>
                      Pages
                    </option>
                    {pages.map((p) => (
                      <option key={p.id} value={p.id}>
                        {p.name}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            </div>
            {inputs.length > 0 &&
              inputs.map((i) => (
                <input
                  key={i.id}
                  type="text"
                  id={i.name}
                  name={`input-${i.id}-${i.name}`}
                  className="bg-gray-50 mt-6 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder={i.name}
                />
              ))}
            <button
              type="submit"
              className="text-white mt-6  hover:-white mt800 bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
            >
              Submit
            </button>
          </div>
        </form>
      </Modal>

      <Modal
        isOpen={isOpenEditLinkModal}
        style={{
          overlay: { zIndex: 999 },
          content: {
            width: "40rem",
            margin: "auto",
            height: "16rem",
            position: "relative",
          },
        }}
      >
        <button onClick={() => setIsOpenEditLinkModal(false)} className="absolute right-4">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
          </svg>
        </button>
        <div className="text-center font-bold">edit Link</div>
        <form onSubmit={handleSubmitEdit}>
          <div>
            <input
              type="text"
              id="link"
              name="link"
              className="bg-gray-50 mt-6 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
              placeholder="Link"
              value={inputEditValue}
              onChange={(e) => setInputEditValue(e.target.value)}
            />
            <button
              type="submit"
              className="text-white mt-6  hover:-white mt800 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
            >
              Submit
            </button>
          </div>
        </form>
      </Modal>
    </Fragment>
  );
};

export default LinksList;
