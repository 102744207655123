import { Outlet } from "react-router";
import Nav from "../common/nav";
import Sidebar from "../common/sidebar";
import MainLayoutProvider from "../contextAPI/mainLayout";
import { useUser } from "../contextAPI/user";
import { useEffect, useState } from "react";
import { IUserSession } from "../utils/types";
import ConfigApi from "./../services/config.json";

const MainLayout = () => {
  const { isLoggedIn } = useUser();
  const [ws, setWs] = useState<null | WebSocket>(null);
  const [data, setData] = useState<IUserSession[]>([]);
  const [filteredData, setfilteredData] = useState<IUserSession[]>([]);
  const [notification, setNotification] = useState(false);
  useEffect(() => {
    if (isLoggedIn) {
      setWs(new WebSocket(ConfigApi.ws));
    }
  }, [isLoggedIn]);

  ws &&
    ws.addEventListener("message", (e) => {
      setData(JSON.parse(e.data));
      setfilteredData(JSON.parse(e.data));
      setNotification(true);
    });

  return (
    <MainLayoutProvider data={filteredData} setData={setData} ws={ws}>
      <div className="min-h-screen bg-gray-50/50">
        <Sidebar />
        <div className="p-4 xl:ml-80">
          <Nav notification={notification} setNotification={setNotification} data={data} setfilteredData={setfilteredData} />
          <Outlet />
        </div>
      </div>
    </MainLayoutProvider>
  );
};

export default MainLayout;
