import { Dispatch, FC, PropsWithChildren, SetStateAction, createContext, useContext, useState } from "react";

interface Props extends PropsWithChildren {
  isLoggedIn: boolean;
  setIsLoggedIn: Dispatch<SetStateAction<boolean>>;
  token: string | null;
  setToken: Dispatch<SetStateAction<null | string>>;
}

interface IUserContext {
  token: string | null;
  setToken: Dispatch<SetStateAction<null | string>>;
  isLoggedIn: boolean;
  setIsLoggedIn: Dispatch<SetStateAction<boolean>>;
}

const userContext = createContext({
  token: null,
  setToken: () => {},
  isLoggedIn: false,
  setIsLoggedIn: () => {},
} as IUserContext);

const UserProvider: FC<Props> = ({ isLoggedIn, setIsLoggedIn, setToken, token, children }) => {
  return <userContext.Provider value={{ token, setToken, isLoggedIn, setIsLoggedIn }}>{children}</userContext.Provider>;
};

export const useUser = () => useContext(userContext);

export default UserProvider;
