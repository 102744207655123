import AuthLayout from "../layouts/auth";
import MainLayout from "../layouts/main";
import Dashboard from "../pages/dashboard";
import Login from "../pages/login";
import Targets from "../pages/targets";

export interface IRoute {
  name: string;
  title: string;
  component?: () => JSX.Element;
  path?:string;
  hasSiderLink?:boolean;
  isPublic?:boolean;
  routes:IRoute[]
}

export interface IRoutes {
  layout: () => JSX.Element;
  routes: IRoute[];
}

export const routes: IRoutes[] = [
  {
    layout: AuthLayout,
    routes: [
      {
        name: "login",
        title: "Login page",
        component: Login,
        path: "/login",
        isPublic: true,
        routes:[]
      },
    ],
  },
  {
    layout: MainLayout,
    routes: [
      {
        name: "dashboard",
        title: "Dashboard page",
        component: Dashboard,
        path: "/",
        routes:[]
      },
      {
        name: "targets",
        title: "Targets page",
        component: Targets,
        path: "/targets",
        routes:[]
      },
      // {
      //   name: "users",
      //   title: "Users",
      //   hasSiderLink: true,
      //   routes: [
      //     {
      //       name: "list-users",
      //       title: "List of users",
      //       hasSiderLink: true,
      //       component: Dashboard,
      //       path: "/users",
      //     },
      //     {
      //       name: "create-user",
      //       title: "Add user",
      //       hasSiderLink: true,
      //       component: Targets,
      //       path: "/users/new",
      //     },
      //   ],
      // },
    ],
  },
];
