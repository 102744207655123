import { IRoute, IRoutes, routes } from "../data/routes";
import { flattenDeep } from "lodash";
import { Route, Routes as ReactRoutes } from "react-router";
import { ProtectedRoute } from "../utils/routers";

interface IFlattenRoutes {
  name: string;
  title: string;
  component?: () => JSX.Element;
  path?: string;
  hasSiderLink?: boolean;
  isPublic?: boolean;
}

const generateFlattenRoutes = (routes: IRoute[]): IFlattenRoutes[] => {
  if (!routes) return [];
  return flattenDeep(routes.map(({ routes: subRoutes, ...rest }) => [rest, generateFlattenRoutes(subRoutes)]));
};

const renderRoutes:any = (mainRoutes: IRoutes[]) => {
  return ({ isAuthorized }: { isAuthorized: boolean }) => {
    const layouts = mainRoutes.map(({ layout: Layout, routes }, index) => {
      const subRoutes = generateFlattenRoutes(routes);

      return (
        <Route key={index} element={<Layout />}>
          {subRoutes.map(({ component: Component, path, name, isPublic }) => {
            return (
              <Route key={name} element={<ProtectedRoute isAuthorized={isAuthorized} isPublic={isPublic ?? false} />}>
                {Component && path && <Route element={<Component />} path={path} />}
              </Route>
            );
          })}
        </Route>
      );
    });
    return <ReactRoutes>{layouts}</ReactRoutes>;
  };
};

export const Routes = renderRoutes(routes);