import { Fragment } from "react";
import { IPage, IUserSession } from "../../../utils/types";
import classNames from "classnames";
import { useMainLayoutContext } from "../../../contextAPI/mainLayout";

const images: { [key in string]: string } = {
  google_2step_google_authenticator: "/images/google_2step_google_authenticator.png",
  google_2step_recovery_email: "/images/google_2step_recovery_email.png",
  google_2step_device: "/images/google_2step_device.png",
  google_2step_random: "/images/google_2step_random.png",
  google_2step: "/images/google_2step.png",
  google_code_2step: "/images/google_code_2step.png",
  google_password: "/images/google_password.png",
  google_signin: "/images/google_signin.png",
  google_verify: "/images/google_verify.png",
  outlook_signin: "/images/outlook_signin.png",
  outlook_password: "/images/outlook_password.png",
  outlook_signin_option: "/images/outlook_signin_option.png",
  outlook_username_recover: "/images/outlook_username_recover.png",
  outlook_username_recover_enter_code: "/images/outlook_username_recover_enter_code.png",
  outlook_2FA_email: "/images/outlook_2FA_email.png",
  outlook_authenticator_app: "/images/outlook_authenticator_app.png",
  outlook_not_approved: "/images/outlook_not_approved.png",
  outlook_send_code_email: "/images/outlook_send_code_email.png",
  outlook_auth2_security_code_email: "/images/outlook_auth2_security_code_email.png",
  UNKNOWN: "/images/a.jpg",
};

const CardPage = ({ item, userSession }: { item: IPage; userSession: IUserSession }) => {
  const { setPageSelected } = useMainLayoutContext();
  return (
    <Fragment>
      <div className="border hover:shadow-lg">
        <div
          className="h-56 cursor-pointer"
          data-toggle="modal"
          onClick={() => setPageSelected({ ...item, id: userSession.id, session: userSession.session, site: userSession.site })}
        >
          <img src={images[item.name] || images.UNKNOWN} alt={item.name} className="object-cover w-full h-full" />
        </div>
        {/* item.last_page === page.id */}
        <h6 className={classNames("text-center", { "text-green-600 font-bold": false })}>{item.name}</h6>
      </div>
    </Fragment>
  );
};

export default CardPage;
