import { Dispatch, FC, FormEvent, Fragment, SetStateAction, useCallback, useEffect, useState } from "react";
import { useUser } from "../../../contextAPI/user";
import http from "./../../../services/http";
import Modal from "react-modal";
import { ITarget } from "../../../utils/types";
import { ITargetSelected } from "..";
import classNames from "classnames";

interface IProps {
  targetSelected: ITargetSelected | null;
  setTargetSelected: Dispatch<SetStateAction<ITargetSelected | null>>;
}

const TargetsList: FC<IProps> = ({ targetSelected, setTargetSelected }) => {
  const { token } = useUser();
  const [targets, setTargets] = useState<ITarget[]>([]);
  const [isOpenCreateTargetModal, setIsOpenCreateTargetModal] = useState(false);
  const [isOpenEditTargetModal, setIsOpenEditTargetModal] = useState(false);
  const [inputEditValue, setInputEditValue] = useState("");

  useEffect(() => {
    if (targetSelected?.section === "EDIT") {
      setInputEditValue(targetSelected.target.name);
    }
  }, [targetSelected]);

  const getListTargets = useCallback(() => {
    http("/target/list", { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        if (res.status === 200) {
          setTargets(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  useEffect(() => {
    getListTargets();
  }, [token, getListTargets]);

  const handleDeleteTarget = (id: number) => {
    const confirm = window.confirm("Are you sure to delete the target?");
    if (confirm) {
      const d = new FormData();
      d.append("id", id.toString());
      http("/target/delete", { method: "delete", headers: { Authorization: `Bearer ${token}` }, data: d })
        .then((res) => {
          if (res.status === 200) {
            getListTargets();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleCreateTarget = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = new FormData(e.currentTarget);
    const d = new FormData();
    for (const [name, value] of form) {
      if (name === "name") {
        d.append("name", value);
      }
    }
    http("/target/create", { headers: { Authorization: `Bearer ${token}` }, method: "POST", data: d })
      .then((res) => {
        if (res.status === 200) {
          getListTargets();
          setIsOpenCreateTargetModal(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSelectForEdit = (t: ITarget) => {
    setTargetSelected({ section: "EDIT", target: t });
    setIsOpenEditTargetModal(true);
  };

  const handleSubmitEdit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const d = new FormData();
    d.append("name", inputEditValue);
    targetSelected?.section === "EDIT" && d.append("id", targetSelected.target.id.toString());
    http("/target/edit", { headers: { Authorization: `Bearer ${token}` }, method: "POST", data: d })
      .then((res) => {
        if (res.status === 200) {
          getListTargets();
          setIsOpenEditTargetModal(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Fragment>
      <div className="w-full text-sm font-medium border border-gray-200 rounded-lg align-top h-fit">
        <div onClick={() => setIsOpenCreateTargetModal(true)} className="w-full text-center px-4 py-2 text-white bg-blue-700 hover:bg-blue-800 border-b border-gray-200 rounded-t-lg cursor-pointer">
          <span>Create Target</span>
        </div>
        <div className="text-xs text-zinc-400 my-2 px-2">List of targets</div>
        {targets.map((t) => (
          <div
            key={t.id}
            className="w-full flex justify-between items-center px-4 py-2 border-b border-gray-200 cursor-pointer hover:bg-gray-100 hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
          >
            <span
              className={classNames({ "text-blue-800 font-bold": targetSelected && targetSelected.section === "LINKS" && targetSelected.target?.id === t.id })}
              onClick={() => setTargetSelected({ section: "LINKS", target: t })}
            >
              {t.name}
            </span>
            <div className="flex">
              <button
                className="border-2 border-red-500 mx-0.5 p-1 rounded-md flex justify-center items-center hover:bg-red-200 group transition-colors"
                onClick={() => handleDeleteTarget(t.id)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" className="text-red-500 ">
                  <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0" />
                </svg>
              </button>
              <button
                className="border-2 border-blue-400 mx-0.5 p-1 rounded-lg flex justify-center items-center hover:bg-blue-200 group transition-colors"
                onClick={() => handleSelectForEdit(t)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" className="text-blue-400">
                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                  <path
                    fillRule="evenodd"
                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                  />
                </svg>
              </button>
            </div>
          </div>
        ))}
      </div>

      <Modal
        isOpen={isOpenCreateTargetModal}
        style={{ overlay: { zIndex: 999 }, content: { width: "40rem", margin: "auto", height: "16rem", position: "relative" } }}
        contentLabel="Example Modal"
      >
        <button onClick={() => setIsOpenCreateTargetModal(false)} className="absolute right-4">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
          </svg>
        </button>
        <div className="text-center font-bold">New Target</div>
        <form onSubmit={handleCreateTarget}>
          <div>
            <input
              type="text"
              id="name"
              name="name"
              className="bg-gray-50 mt-6 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
              placeholder="Name"
            />
            <button
              type="submit"
              className="text-white mt-6 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
            >
              Submit
            </button>
          </div>
        </form>
      </Modal>

      <Modal isOpen={isOpenEditTargetModal} style={{ overlay: { zIndex: 999 }, content: { width: "40rem", margin: "auto", height: "16rem", position: "relative" } }}>
        <button onClick={() => setIsOpenEditTargetModal(false)} className="absolute right-4">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
          </svg>
        </button>
        <div className="text-center font-bold">edit Target</div>
        <form onSubmit={handleSubmitEdit}>
          <div>
            <input
              type="text"
              id="name"
              name="name"
              className="bg-gray-50 mt-6 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
              placeholder="Name"
              value={inputEditValue}
              onChange={(e) => setInputEditValue(e.target.value)}
            />
            <button
              type="submit"
              className="text-white mt-6 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
            >
              Submit
            </button>
          </div>
        </form>
      </Modal>
    </Fragment>
  );
};

export default TargetsList;
