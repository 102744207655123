import { Navigate, Outlet } from "react-router";

interface IProps {
  isPublic: boolean;
  isAuthorized: boolean;
}

export const ProtectedRoute = ({ isAuthorized, isPublic }: IProps) => {
  return (isPublic || isAuthorized) ? <Outlet /> : <Navigate to="/login" />;
};
